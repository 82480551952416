import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useItems() {
  const items = ref([])
  const item = ref({})
  const searchItem = ref('')
  const loading = ref(false)

  const getItems = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/items', { params })
        .then(response => {
          const { data } = response.data
          items.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          loading.value = false
          reject(error)
        })
    })
  }

  const getItem = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/items/${id}`)
        .then(response => {
          const { data } = response
          item.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    items,
    searchItem,

    getItems,
    getItem,
  }
}
