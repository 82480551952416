<template>
  <v-card>
    <v-card-title>Item</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="saveItem"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="form.description"
              label="Description"
              rows="1"
              outlined
              dense
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiPlus, mdiCalendarCheckOutline, mdiSpeedometer, mdiClose, mdiCogOutline,
} from '@mdi/js'
import axios from '@axios'
import router from '@/router'
import useItems from '../useItems'

const formInit = () => ({
  id: null,
  description: null,
})
export default {
  setup() {
    const saving = ref(false)
    const itemTab = ref(null)
    const form = ref(formInit())
    const errors = ref([])

    const { item, getItem } = useItems()
    const resetForm = () => {
      form.value = formInit()
    }

    const saveItem = () => {
      saving.value = true
      if (form.value.id) {
        axios
          .put(`/items/${form.value.id}`, form.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'items' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        axios
          .post('/items', form.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'items' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    if (router.currentRoute.params.id) {
      saving.value = true
      getItem(router.currentRoute.params.id).then(response => {
        const { data } = response
        form.value = data
        saving.value = false
      })
    }

    return {
      itemTab,
      form,
      errors,
      saving,

      item,
      getItem,

      resetForm,
      saveItem,

      // icons
      icons: {
        mdiPlus,
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>
